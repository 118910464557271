.slide-bench {
  animation: slide-bench 0.5s ease-in-out;
}
@keyframes slide-bench {
  from {
    transform: translateY(63px);
  }
  to {
    transform: translateY(0px);
  }
}


.slide-squat {
  animation: slide-squat 0.5s ease-in-out;
}
@keyframes slide-squat {
  from {
    transform: translateY(63px) translateX(-88px);
  }
  to {
    transform: translateY(0px);
  }
}

.slide-deadlift {
  animation: slide-deadlift 0.5s ease-in-out;
}
@keyframes slide-deadlift {
  from {
    transform: translateY(63px) translateX(176px);
  }
  to {
    transform: translateY(0px);
  }
}

.lifterBoxFront {
  transform-style: preserve-3d;
}
.lifterBoxBack {
  transform: translateZ(-1em);
}

.slide-records {
  animation-name: slide-records;
  animation-duration: 500ms;
  animation-delay: 0ms;
  animation-timing-function: ease-in-out;
  view-transition-name: records;
  z-index: 2;
}

.slide-lifter {
  animation-name: slide-lifter;
  animation-duration: 500ms;
  animation-delay: 0ms;
  animation-timing-function: ease-in-out;
  view-transition-name: lifter;
  z-index: 2;
}

.slide-attempt {
  animation-name: slide-attempt;
  animation-duration: 500ms;
  animation-delay: 0ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  view-transition-name: attempt;
  z-index: 3;
}

.slide-best {
  animation-name: slide-best;
  animation-duration: 500ms;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
  view-transition-name: best;
}

.slide-name {
  animation-name: slide-name;
  view-transition-name: name;
}

.slide-name-2 {
  animation-delay: 1000ms;
  animation-name: slide-name-2;
  view-transition-name: name-2;
  transform-origin: 960px 1080px;
}

.slide-lift {
  animation-name: slide-lift;
  view-transition-name: lift;
}

.slide-lift-2 {
  animation-delay: 1000ms;
  animation-name: slide-lift-2;
  view-transition-name: lift-2;
  transform-origin: 960px 1080px;
}

.slide-info-pbs {
  animation-duration: 500ms;
  animation-delay: 500ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  z-index: 1;
}

.slide-info {
  animation-name: slide-info;
  view-transition-name: info;
}

.slide-pbs {
  animation-name: slide-pbs;
  view-transition-name: pbs;
}

.blinker {
  animation: blinker;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: 5;
}

@keyframes blinker {
  50% {
    opacity: 0.2;
  }
}

.blinkerOff {
  animation: blinkerOff;
  animation-duration: 1000ms;
  animation-timing-function: linear;
  animation-iteration-count: 5;
}

@keyframes blinkerOff {
  50% {
    opacity: 0.2;
  }
}