// vim: set ts=2 sts=2 sw=2 et:
//
// This file is part of OpenLifter, simple Powerlifting meet software.
// Copyright (C) 2019 The OpenPowerlifting Project.
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU Affero General Public License as
// published by the Free Software Foundation, either version 3 of the
// License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU Affero General Public License for more details.
//
// You should have received a copy of the GNU Affero General Public License
// along with this program.  If not, see <https://www.gnu.org/licenses/>.

@font-face {
  font-family: 'TitilliumWeb-Bold';
  src: url('/stream/TitilliumWeb-Bold.ttf')  format('truetype');
}

@font-face {
  font-family: 'American Captain';
  src: url('/stream/American Captain.ttf')  format('truetype');
}

@font-face {
  font-family: 'BarlowCondensed-BlackItalic';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-BlackItalic.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-Black';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-Black.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-BoldItalic';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-BoldItalic.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-Bold';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-Bold.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-ExtraBoldItalic';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-ExtraBoldItalic.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-ExtraBold';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-ExtraBold.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-ExtraLightItalic';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-ExtraLightItalic.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-ExtraLight';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-ExtraLight.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-Italic';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-Italic.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-LightItalic';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-LightItalic.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-Light';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-Light.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-MediumItalic';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-MediumItalic.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-Medium';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-Medium.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-Regular';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-Regular.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-SemiBoldItalic';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-SemiBoldItalic.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-SemiBold';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-SemiBold.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-ThinItalic';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-ThinItalic.ttf')  format('truetype');
}
@font-face {
  font-family: 'BarlowCondensed-Thin';
  src: url('/stream/Barlow_Condensed/BarlowCondensed-Thin.ttf')  format('truetype');
}

$border-rounding: 16px;
//$BackgroundColour: #1463ba;
$BackgroundColour: #ac1919d1;

//$leftMargin: 10px;
$leftMargin: 0;
$TitleBackgroundColour: black;
$BestLiftBackgroundColour: black;
$BestLiftForegroundColour: white;
$TitleColour: white;
$ContentAreaBackgroundColour: #eeeeee;
$ContentAreaTextColour: white;

div.DataContainer {
  color: white;
  position: relative;
//  bottom: 0;
//  bottom-margin: 10px;
}

.Container {
  font-size: 20px;
  width: 30%;
  display: flex;
  position: absolute;
}

.MainContainer {
  color: #10202B;
}

.ContentAreaContainer {
  color: $ContentAreaTextColour;
  display: flex;
  flex: 1;
  font-family: TitilliumWeb-Bold;  
}

.MainContentAreaContainer {
  display: flex;
  flex-direction: column;
//  justify-content: space-around;
  justify-content: flex-start;
  flex: 1;
}

.InfoContainer {
  color: $TitleColour;
  height: 36px;
}

.NameContainer {
  color: $TitleColour;
  height: 36px;
}

.LiftContainer {
  color: $TitleColour;
  height: 36px;
}

.UpNextContainer {
  font-family: TitilliumWeb-Bold;  
  font-size: 36px;
  font-weight: 600;
  color: $TitleColour;
  position: relative;
}

.RecordAttemptContainer {
  font-family: TitilliumWeb-Bold;  
  font-size: 36px;
  font-weight: 600;
  color: $TitleColour;
  position: relative;
}

.flexGrid {
  display: flex;
  flex-direction: column;
}

.flexGrid .col {
  display: flex;
  flex-direction: row;
  flex-basis: fit-content;
  line-height: 1.3;
}

.col1 {
  width: 300px;
}

.col2 {
  width: 150px;
}

.Branding {
  margin-left: $leftMargin;
  /*border-bottom-color: black;
  border-bottom-style: solid;*/
  font-size: 35px;
  /* -webkit-text-stroke: 0.5px black; /* width and color */
}

.Timer {
  margin-left: $leftMargin;
  /*border-bottom-color: black;
  border-bottom-style: solid;*/
  font-size: 35px;
  /* -webkit-text-stroke: 0.5px black; /* width and color */
}

.LifterName {
  margin-left: $leftMargin;
  font-size: 30px;
  font-weight: 600;
  font-family: "TitilliumWeb-Bold";
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.LifterInfo {
  margin-left: $leftMargin;
  font-size: 24px;
  font-weight: 600;
  font-family: "TitilliumWeb-Bold";
}

.CurrentLiftType {
  margin-left: $leftMargin;
  font-size: 35px;
}

.LiftNameBubble {
  width: 630px;
  font-size: 26px;
  font-weight: 700;
  font-family: "TitilliumWeb-Bold";
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 36px;
  color: white;
}

.LiftResultBubble {
  width: 72px;
  font-size: 24px;
  font-weight: 600;
  font-family: "TitilliumWeb-Bold";
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 26px;
  color: white;
  background-color: $BackgroundColour;
}

.LiftResultBubbleBox {
  width: 80px;
//  flex: 1;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  height: 32px;
  color: white;
  border-color: white;
  border-style: solid;
  border-width: 1px;
}

.LiftResultSpacer {
  width: 80px;
//  flex: 1;
  font-size: 26px;
  display: flex;
  margin-right: 10px;
  height: 32px;
}

$recordNotificationAnimFromColor: black;
$recorNotificationdAnimToColor: rgb(209, 4, 55);

.RecordAttemptNotice {
  -webkit-animation: record_color_change 1s infinite alternate;
  -moz-animation: record_color_change 1s infinite alternate;
  -ms-animation: record_color_change 1s infinite alternate;
  -o-animation: record_color_change 1s infinite alternate;
  animation: record_color_change 1s infinite alternate;
}

 @-webkit-keyframes record_color_change {
  from { color: $recordNotificationAnimFromColor }
  to { color: $recorNotificationdAnimToColor; }
 }
 @-moz-keyframes record_color_change {
  from { color: $recordNotificationAnimFromColor }
  to { color: $recorNotificationdAnimToColor; }
 }
 @-ms-keyframes record_color_change {
  from { color: $recordNotificationAnimFromColor }
  to { color: $recorNotificationdAnimToColor; }
 }
 @-o-keyframes record_color_change {
  from { color: $recordNotificationAnimFromColor }
  to { color: $recorNotificationdAnimToColor; }
 }
 @keyframes record_color_change {
  from { color: $recordNotificationAnimFromColor }
  to { color: $recorNotificationdAnimToColor; }
 }

$currentLiftRecordFromColor: white;
$currentLiftRecordToColor: red;

.CurrentLiftAnimate {
  -webkit-animation: current_lift_record_color_change 1s infinite alternate;
  -moz-animation: current_lift_record_color_change 1s infinite alternate;
  -ms-animation: current_lift_record_color_change 1s infinite alternate;
  -o-animation: current_lift_record_color_change 1s infinite alternate;
  animation: current_lift_record_color_change 1s infinite alternate;
}

 @-webkit-keyframes current_lift_record_color_change {
  from { color: $currentLiftRecordFromColor }
  to { color: $currentLiftRecordToColor; }
 }
 @-moz-keyframes current_lift_record_color_change {
  from { color: $currentLiftRecordFromColor }
  to { color: $currentLiftRecordToColor; }
 }
 @-ms-keyframes current_lift_record_color_change {
  from { color: $currentLiftRecordFromColor }
  to { color: $currentLiftRecordToColor; }
 }
 @-o-keyframes current_lift_record_color_change {
  from { color: $currentLiftRecordFromColor }
  to { color: $currentLiftRecordToColor; }
 }
 @keyframes current_lift_record_color_change {
  from { color: $currentLiftRecordFromColor }
  to { color: $currentLiftRecordToColor; }
 }
